import { DetailedHTMLProps, HTMLAttributes } from 'react';
import cn from 'classnames';
// Styles
import styles from './Text.module.scss';

export type Color =
  | 'accent-default'
  | 'accent-bright'
  | 'accent-dim'
  | 'background-default'
  | 'background-light'
  | 'background-dim'
  | 'light-high'
  | 'light-medium'
  | 'light-low'
  | 'light-dim'
  | 'border-default'
  | 'border-bright'
  | 'purple-default'
  | 'purple-tint'
  | 'turquoise-default'
  | 'turquoise-tint'
  | 'blue-default'
  | 'blue-tint'
  | 'warning-default'
  | 'warning-bright'
  | 'warning-tint'
  | 'warning-dim'
  | 'error-default'
  | 'error-bright'
  | 'error-tint'
  | 'error-dim'
  | 'success-default'
  | 'success-bright'
  | 'success-tint'
  | 'success-dim';

export type TextVariants =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'subtitle3'
  | 'body1Regular18'
  | 'body1Regular16'
  | 'body1Medium18'
  | 'body1Medium16'
  | 'body2Regular'
  | 'body2Medium'
  | 'body3Regular'
  | 'body3Medium'
  | 'body4Regular'
  | 'body4Medium'
  | 'buttonL'
  | 'buttonM'
  | 'buttonS'
  | 'captionRegular'
  | 'overlineMedium'
  | 'xLargeLabelRegular'
  | 'xLargeLabelBold'
  | 'largeLabelRegular'
  | 'largeLabelBold'
  | 'smallLabelRegular'
  | 'smallLabelBold'
  | 'xSmallLabelRegular'
  | 'xSmallLabelBold';

type TextProps = DetailedHTMLProps<
  HTMLAttributes<HTMLParagraphElement>,
  HTMLParagraphElement
> & {
  isEllipsis?: boolean;
  isError?: boolean;
  isSuccess?: boolean;
  uppercase?: boolean;
  lowercase?: boolean;
  variant?: TextVariants | null;
  textAlign?: 'center' | 'right';
};

const Text = ({
  children,
  isEllipsis,
  isError,
  isSuccess,
  uppercase,
  lowercase,
  color = 'lights-high',
  variant,
  textAlign,
  className,
  ...rest
}: TextProps) => {
  const resolvedVariant = variant ? variant : 'body1Regular16';
  return (
    <p
      className={cn(
        styles.text,
        {
          [styles.ellipsis]: isEllipsis,
          [styles.error]: isError,
          [styles.success]: isSuccess,
          [styles.uppercase]: uppercase,
          [styles.lowercase]: lowercase,
          [styles[`color-${color}`]]: color,
          [styles[`align-${textAlign}`]]: textAlign,
          [styles[resolvedVariant]]: variant !== null,
        },
        className
      )}
      {...rest}
    >
      {children}
    </p>
  );
};

export default Text;
