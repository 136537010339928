import { forwardRef, useState } from 'react';
import cn from 'classnames';

import Text from 'ui3/Text/Text';
import MillionsIcon, { IconNames } from 'ui3/MillionsIcon/MillionsIcon';

import styles from './Input.module.scss';

export type InputSize = 'large' | 'medium' | 'small';

type InputProps = {
  type?: React.InputHTMLAttributes<HTMLInputElement>['type'];
  name: string;
  label?: string;
  placeholder?: string;
  fieldSize?: InputSize;
  className?: string;
  id?: string;
  error?: string;
  icon?: IconNames;
  hint?: string;
  notice?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      fieldSize = 'large',
      name,
      id = name,
      className,
      label,
      error,
      icon,
      type = 'text',
      hint,
      notice,
      maxLength,
      value,
      onChange,
      ...restProps
    },
    ref
  ) => {
    const [inputType, setInputType] = useState(type);
    const hasError = Boolean(error);
    const isPassword = type === 'password';

    const showCounter = maxLength !== undefined;
    const [counter, setCounter] = useState(value?.toString().length || 0);

    const handlePasswordVisibilityToggle: React.MouseEventHandler<HTMLButtonElement> = (
      e
    ) => {
      e.preventDefault();

      if (isPassword) {
        setInputType((prevState) =>
          prevState === 'password' ? 'text' : 'password'
        );
      }
    };

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(event);
      setCounter(event.target.value.length);
    };

    return (
      <div className={cn(styles.root, className)}>
        {label && (
          <label htmlFor={id} className={styles.label}>
            {label}
          </label>
        )}

        <div className={cn(styles.inputWrapper, { [styles.error]: hasError })}>
          {icon && (
            <MillionsIcon
              className={cn(styles.icon, styles[fieldSize])}
              name={icon}
            />
          )}
          <input
            name={name}
            data-testid={id}
            id={id}
            ref={ref}
            className={cn(styles.input, styles[fieldSize], {
              [styles.error]: hasError,
              [styles.withIcon]: Boolean(icon),
            })}
            type={inputType}
            value={value}
            onChange={handleOnChange}
            maxLength={maxLength}
            {...restProps}
          />
          {isPassword && (
            <button
              type="button"
              className={cn(styles.passwordToggle, styles[fieldSize])}
              onClick={handlePasswordVisibilityToggle}
            >
              {inputType === 'password' ? (
                <MillionsIcon name="eyeOff" />
              ) : (
                <MillionsIcon name="eye" />
              )}
            </button>
          )}
        </div>

        {(hint || showCounter) && !error && (
          <div
            className={cn(styles.hintWrapper, {
              [styles.onlyCounter]: showCounter && !hint,
            })}
          >
            {hint && (
              <span className={styles.hint}>
                <MillionsIcon name="infoCircle" size={16} />
                <Text variant="captionRegular" color="lights-low">
                  {hint}
                </Text>
              </span>
            )}

            {showCounter && (
              <Text
                variant="captionRegular"
                color="lights-low"
                textAlign="right"
              >
                {`${counter}/${maxLength}`}
              </Text>
            )}
          </div>
        )}

        {notice && (
          <Text variant="smallLabelRegular" className={styles.noticeLabel}>
            {notice}
          </Text>
        )}

        {hasError && (
          <Text variant="captionRegular" color="error-default">
            {error}
          </Text>
        )}
      </div>
    );
  }
);

Input.displayName = 'Input';

export default Input;
