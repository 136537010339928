import React from 'react';
import cn from 'classnames';

import AlertTriangleIcon from '../../public/images/iconsV3/AlertTriangle.svg';
import ArrowDownIcon from '../../public/images/iconsV3/ArrowDown.svg';
import ArrowLeftIcon from '../../public/images/iconsV3/ArrowLeft.svg';
import ArrowRightIcon from '../../public/images/iconsV3/ArrowRight.svg';
import ArrowUpIcon from '../../public/images/iconsV3/ArrowUp.svg';
import AwardIcon from '../../public/images/iconsV3/Award.svg';
import BagIcon from '../../public/images/iconsV3/Bag.svg';
import CalendarCheckIcon from '../../public/images/iconsV3/CalendarCheck.svg';
import CalendarDaysIcon from '../../public/images/iconsV3/CalendarDays.svg';
import CalendarIcon from '../../public/images/iconsV3/Calendar.svg';
import CheckAll from '../../public/images/iconsV3/CheckAll.svg';
import CheckCircleIcon from '../../public/images/iconsV3/CheckCircle.svg';
import CheckIcon from '../../public/images/iconsV3/Check.svg';
import ChevronDownIcon from '../../public/images/iconsV3/ChevronDown.svg';
import ChevronLeftIcon from '../../public/images/iconsV3/ChevronLeft.svg';
import ChevronRightIcon from '../../public/images/iconsV3/ChevronRight.svg';
import ChevronUpIcon from '../../public/images/iconsV3/ChevronUp.svg';
import CloseIcon from '../../public/images/iconsV3/Close.svg';
import CogIcon from '../../public/images/iconsV3/Cog.svg';
import DollarIcon from '../../public/images/iconsV3/Dollar.svg';
import DownloadIcon from '../../public/images/iconsV3/Download.svg';
import FilterIcon from '../../public/images/iconsV3/Filter.svg';
import FilterOffIcon from '../../public/images/iconsV3/FilterOff.svg';
import HomeIcon from '../../public/images/iconsV3/Home.svg';
import HomeOutlineIcon from '../../public/images/iconsV3/HomeOutline.svg';
import ImageIcon from '../../public/images/iconsV3/Image.svg';
import InfoCircleIcon from '../../public/images/iconsV3/InfoCircle.svg';
import InfoCircleOutlineIcon from '../../public/images/iconsV3/InfoCircleOutline.svg';
import MicrophoneIcon from '../../public/images/iconsV3/Microphone.svg';
import MicrophoneOutlineIcon from '../../public/images/iconsV3/MicrophoneOutline.svg';
import MinusCircleIcon from '../../public/images/iconsV3/MinusCircle.svg';
import MoreHorizontal from '../../public/images/iconsV3/MoreHorizontal.svg';
import NotificationBell from '../../public/images/iconsV3/NotificationBell.svg';
import PencilIcon from '../../public/images/iconsV3/Pencil.svg';
import PlayIcon from '../../public/images/iconsV3/Play.svg';
import PlaySolidIcon from '../../public/images/iconsV3/PlaySolid.svg';
import PlusCircleIcon from '../../public/images/iconsV3/PlusCircle.svg';
import PlusCircleOutlineIcon from '../../public/images/iconsV3/PlusCircleOutline.svg';
import PlusIcon from '../../public/images/iconsV3/Plus.svg';
import SearchIcon from '../../public/images/iconsV3/Search.svg';
import Share from '../../public/images/iconsV3/Share.svg';
import StarIcon from '../../public/images/iconsV3/Star.svg';
import StarOutlineIconIcon from '../../public/images/iconsV3/StarOutline.svg';
import StreamIcon from '../../public/images/iconsV3/Stream.svg';
import TagIcon from '../../public/images/iconsV3/Tag.svg';
import TagOutlineIcon from '../../public/images/iconsV3/TagOutline.svg';
import TimerIcon from '../../public/images/iconsV3/Timer.svg';
import TrashIcon from '../../public/images/iconsV3/Trash.svg';
import UploadIcon from '../../public/images/iconsV3/Upload.svg';
import VideoIcon from '../../public/images/iconsV3/Video.svg';
import EyeIcon from '../../public/images/iconsV3/Eye.svg';
import EyeOffIcon from '../../public/images/iconsV3/EyeOff.svg';
import UserIcon from '../../public/images/iconsV3/User.svg';
import ShoppingCartIcon from '../../public/images/iconsV3/ShoppingCart.svg';
import LogoutIcon from '../../public/images/iconsV3/Logout.svg';
import CircleSmallIcon from '../../public/images/iconsV3/CircleSmall.svg';
import GoogleIcon from '../../public/images/iconsV3/Google.svg';
import FacebookIcon from '../../public/images/iconsV3/Facebook.svg';
import XIcon from '../../public/images/iconsV3/X.svg';
import TikTokIcon from '../../public/images/iconsV3/TikTok.svg';
import YoutubeIcon from '../../public/images/iconsV3/Youtube.svg';
import InstagramIcon from '../../public/images/iconsV3/Instagram.svg';
import ShirtOutlineIcon from '../../public/images/iconsV3/ShirtOutline.svg';
import ShoppingBagIcon from '../../public/images/iconsV3/ShoppingBag.svg';
import RecordIcon from '../../public/images/iconsV3/Record.svg';
import Heart from '../../public/images/icons/heart-icon.svg';
import MailIcon from '../../public/images/iconsV3/MailIcon.svg';
import MinusIcon from '../../public/images/iconsV3/Minus.svg';
import InfoImage from '../../public/images/footer/infoImage.svg';
import PauseSolidIcon from '../../public/images/iconsV3/PauseSolid.svg';
import CopyIcon from '../../public/images/iconsV3/Copy.svg';
import CloudUpload from '../../public/images/iconsV3/CloudUpload.svg';
import SolidAddIcon from '../../public/images/iconsV3/SolidAdd.svg';
import FilterOutline from '../../public/images/iconsV3/FilterOutline.svg';
import Bitcoin from '../../public/images/iconsV3/Bitcoin.svg';
import Weed from '../../public/images/iconsV3/Weed.svg';
import Alcohol from '../../public/images/iconsV3/Alcohol.svg';
import Cigarette from '../../public/images/iconsV3/Cigarette.svg';
import Dice from '../../public/images/iconsV3/Dice.svg';
import Dashboard from '../../public/images/iconsV3/Dashboard.svg';

import styles from './MillionsIcon.module.scss';

const ICONS = {
  alertTriangle: AlertTriangleIcon,
  arrowDown: ArrowDownIcon,
  arrowLeft: ArrowLeftIcon,
  arrowRight: ArrowRightIcon,
  arrowUp: ArrowUpIcon,
  award: AwardIcon,
  bag: BagIcon,
  calendar: CalendarIcon,
  calendarCheck: CalendarCheckIcon,
  calendarDays: CalendarDaysIcon,
  check: CheckIcon,
  checkAll: CheckAll,
  checkCircle: CheckCircleIcon,
  chevronDown: ChevronDownIcon,
  chevronLeft: ChevronLeftIcon,
  chevronRight: ChevronRightIcon,
  chevronUp: ChevronUpIcon,
  close: CloseIcon,
  cog: CogIcon,
  dollar: DollarIcon,
  download: DownloadIcon,
  filter: FilterIcon,
  filterOff: FilterOffIcon,
  home: HomeIcon,
  homeOutline: HomeOutlineIcon,
  image: ImageIcon,
  infoCircle: InfoCircleIcon,
  infoCircleOutline: InfoCircleOutlineIcon,
  microphone: MicrophoneIcon,
  microphoneOutline: MicrophoneOutlineIcon,
  minusCircle: MinusCircleIcon,
  moreHorizontal: MoreHorizontal,
  notificationBell: NotificationBell,
  pencil: PencilIcon,
  play: PlayIcon,
  playSolid: PlaySolidIcon,
  plus: PlusIcon,
  plusCircle: PlusCircleIcon,
  plusCircleOutline: PlusCircleOutlineIcon,
  search: SearchIcon,
  share: Share,
  star: StarIcon,
  starOutline: StarOutlineIconIcon,
  stream: StreamIcon,
  tag: TagIcon,
  tagOutline: TagOutlineIcon,
  timer: TimerIcon,
  trash: TrashIcon,
  upload: UploadIcon,
  video: VideoIcon,
  eye: EyeIcon,
  eyeOff: EyeOffIcon,
  user: UserIcon,
  shoppingCart: ShoppingCartIcon,
  logout: LogoutIcon,
  circleSmall: CircleSmallIcon,
  google: GoogleIcon,
  facebook: FacebookIcon,
  x: XIcon,
  tiktok: TikTokIcon,
  youtube: YoutubeIcon,
  instagram: InstagramIcon,
  shirtOutline: ShirtOutlineIcon,
  shoppingBag: ShoppingBagIcon,
  record: RecordIcon,
  heart: Heart,
  mail: MailIcon,
  minus: MinusIcon,
  infoImage: InfoImage,
  pauseSolid: PauseSolidIcon,
  copy: CopyIcon,
  cloudUpload: CloudUpload,
  solidAdd: SolidAddIcon,
  filterOutline: FilterOutline,
  weed: Weed,
  alcohol: Alcohol,
  bitcoin: Bitcoin,
  dice: Dice,
  cigarette: Cigarette,
  v3Dashboard: Dashboard,
};

export type IconNames = keyof typeof ICONS;

export type MillionsIconProps = React.ComponentPropsWithoutRef<'svg'> & {
  name: IconNames;
  className?: string;
  withoutFill?: boolean;
  size?: number;
};

const MillionsIcon = ({
  name,
  className,
  size = 24,
  ...rest
}: MillionsIconProps) => {
  const SvgTag = ICONS[name] as 'svg';

  return (
    <SvgTag
      width={size}
      height={size}
      className={cn(styles.root, className)}
      data-icon-name={name}
      aria-hidden="true"
      role="presentation"
      focusable="false"
      {...rest}
    />
  );
};

export default MillionsIcon;
